import React from 'react';
import { responsiveFontSizes } from "@mui/material";
import { CssBaseline, Box } from '@mui/material';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import mui3Theme from "./styles/mui3Theme";
import Home from "./pages/Home";
import About from "./pages/About";
import { 
  Route, 
  Routes 
} from "react-router-dom";

function App() {

  const responsiveMui3Theme = responsiveFontSizes(mui3Theme);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={responsiveMui3Theme}>
        <CssBaseline />
        <Box sx={{ display: "flex"}}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Box>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
