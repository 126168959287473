import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { 
  getDownloadURL, 
  ref 
} from "firebase/storage";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { imagesRef } from "../firebase";
//import NotVerifiedMsg from "../components/NotVerifiedMsg";

export default function Home(): ReactElement {
  const welcomeImageFileName = "gratisports_logo.png";
  const [welcomeImageUrl, setWelcomeImageUrl] = useState("");

  useEffect(() => {
    getDownloadURL(ref(imagesRef, welcomeImageFileName)).then((url) => {
        setWelcomeImageUrl(url);
      }).catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Box alignContent="center" sx={{maxWidth: 640}}>
    <Card sx={{ maxWidth: 640 }}> 
      <CardMedia component="img" image={welcomeImageUrl} alt="Welcome" />
      <CardContent>
        <Typography variant="h5" color="text.primary" align="center">
          Your Sports, Simplified.
        </Typography>
        <Divider sx={{my: 8}}/>
        <Typography variant="body2" color="text.secondary" align="center">
          Our mission is to streamline the organization of sports events, leagues, and tournaments, allowing everyone to focus on the joy of playing. 
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          We are equally dedicated to investing in youth sports, ensuring that every child has the opportunity to learn, play, and thrive in a sporting environment.
        </Typography>
      </CardContent>
    </Card>
    </Box>

  );
}