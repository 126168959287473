import { extendTheme } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    inactive?: PaletteOptions['primary'];
    tertiary?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    container?: string;
    onContainer?: string;
    outline?: string;
    outlineVariant?: string;
    surface?: string;
    onSurface?: string;
    surfaceVariant?: string;
    onSurfaceVariant?: string;
    tertiary?: string,
    onTertiary?: string,
    tertiaryContainer?: string,
    onTertiaryContainer?: string,
  }

  interface SimplePaletteColorOptions {
    container?: string;
    onContainer?: string;
    outline?: string;
    outlineVariant?: string;
    surface?: string;
    onSurface?: string;
    surfaceVariant?: string;
    onSurfaceVariant?: string;
    tertiary?: string,
    onTertiary?: string,
    tertiaryContainer?: string,
    onTertiaryContainer?: string,
  }
}

const iPhoneInput = {
  styleOverrides: {
    root: {
      "*": {
        "userSelect": "text !important" /* Standard syntax */,
      },
    },
  },
};

const mui3Theme = extendTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 14,
  },

  colorSchemes: {
    dark: {
      palette: {
        primary: {
          light: "#eacf89",
          main: "#E5C36C",
          dark: "#a0884b",
          container: "#594400",
          onContainer: "#FFDF92",
          outline: "#989080",
          outlineVariant: "#4C4639",
          surface: "#16130B",
          onSurface: "#EAE1D4",
          surfaceVariant: "#4C4639",
          onSurfaceVariant: "#CFC5B4",
          contrastText: "#3E2E00",
        },
        secondary: {
          light: "#ded0b3",
          main: "#D6C5A0",
          dark: "#958970",
          container: "#51462A",
          onContainer: "#F3E1BB",
          contrastText: "#392F15"
        },
        tertiary: {
          light: "#AECFAC",
          main: "#AECFAC",
          dark: "#AECFAC",
          container: "#314D33",
          onContainer: "#C9EBC7",
          contrastText: "#1A361E",
        },
        inactive: {
          light: "#E5C36C",
          main: "#E5C36C",
          dark: "#E9C770",
          container: "#C9EBC7",
          onContainer: "#486549",
          contrastText: "#E9C770",
        },
        error: {
          main: "#FFB4AB",
          contrastText: "#690005",
        },
      }
    },
    light: {
      palette: {
        primary: {
          light: "#8f7b3b",
          main: "#745B0B",
          dark: "#513f07",
          container: "#FFDF92",
          onContainer: "#241A00",
          outline: "#7E7667",
          outlineVariant: "#CFC5B4",
          surface: "#FFF8F1",
          onSurface: "#1F1B13",
          surfaceVariant: "#EBE1CF",
          onSurfaceVariant: "#4C4639",
          contrastText: "#FFFFFF",
        },
        secondary: {
          light: "#877d65",
          main: "#6A5D3F",
          dark: "#4a412c",
          container: "#F3E1BB",
          onContainer: "#231A04",
          contrastText: "#FFFFFF",
        },
        tertiary: {
          light: "#486549",
          main: "#486549",
          dark: "#486549",
          container: "#C9EBC7",
          onContainer: "#04210B",
          contrastText: "#FFFFFF",
        },
        inactive: {
          light: "#E5C36C",
          main: "#725908",
          dark: "#392B00",
          container: "#E2D9CC",
          onContainer: "#725908",
          contrastText: "#392B00",
        },
        error: {
          main: "#BA1A1A",
          contrastText: "#FFFFFF",
        },
      },
    }
  },
  spacing: 2,
  components: {
    MuiTextField: iPhoneInput,
    MuiInput: iPhoneInput,
    MuiInputBase: iPhoneInput,
    MuiFilledInput: iPhoneInput,
    MuiOutlinedInput: iPhoneInput,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default mui3Theme;

/* 
{
  "description": "TYPE: CUSTOM\nMaterial Theme Builder export 2024-08-13 08:37:08",
  "seed": "#846D26",
  "coreColors": {
      "primary": "#846D26"
  },
  "extendedColors": [],
  "schemes": {
      "light": {
          "primary": "#735C0C",
          "surfaceTint": "#735C0C",
          "onPrimary": "#FFFFFF",
          "primaryContainer": "#FFE08A",
          "onPrimaryContainer": "#241A00",
          "secondary": "#695D3F",
          "onSecondary": "#FFFFFF",
          "secondaryContainer": "#F2E1BB",
          "onSecondaryContainer": "#231B04",
          "tertiary": "#47664A",
          "onTertiary": "#FFFFFF",
          "tertiaryContainer": "#C8ECC9",
          "onTertiaryContainer": "#03210C",
          "error": "#BA1A1A",
          "onError": "#FFFFFF",
          "errorContainer": "#FFDAD6",
          "onErrorContainer": "#410002",
          "background": "#FFF8F1",
          "onBackground": "#1F1B13",
          "surface": "#FFF8F1",
          "onSurface": "#1F1B13",
          "surfaceVariant": "#EBE1CF",
          "onSurfaceVariant": "#4C4639",
          "outline": "#7D7667",
          "outlineVariant": "#CFC6B4",
          "shadow": "#000000",
          "scrim": "#000000",
          "inverseSurface": "#343027",
          "inverseOnSurface": "#F8F0E2",
          "inversePrimary": "#E2C46D",
          "primaryFixed": "#FFE08A",
          "onPrimaryFixed": "#241A00",
          "primaryFixedDim": "#E2C46D",
          "onPrimaryFixedVariant": "#574400",
          "secondaryFixed": "#F2E1BB",
          "onSecondaryFixed": "#231B04",
          "secondaryFixedDim": "#D5C5A1",
          "onSecondaryFixedVariant": "#50462A",
          "tertiaryFixed": "#C8ECC9",
          "onTertiaryFixed": "#03210C",
          "tertiaryFixedDim": "#ADCFAE",
          "onTertiaryFixedVariant": "#2F4D34",
          "surfaceDim": "#E1D9CC",
          "surfaceBright": "#FFF8F1",
          "surfaceContainerLowest": "#FFFFFF",
          "surfaceContainerLow": "#FBF3E5",
          "surfaceContainer": "#F5EDDF",
          "surfaceContainerHigh": "#F0E7D9",
          "surfaceContainerHighest": "#EAE1D4"
      },
      "light-medium-contrast": {
          "primary": "#534100",
          "surfaceTint": "#735C0C",
          "onPrimary": "#FFFFFF",
          "primaryContainer": "#8B7223",
          "onPrimaryContainer": "#FFFFFF",
          "secondary": "#4C4226",
          "onSecondary": "#FFFFFF",
          "secondaryContainer": "#807454",
          "onSecondaryContainer": "#FFFFFF",
          "tertiary": "#2B4930",
          "onTertiary": "#FFFFFF",
          "tertiaryContainer": "#5C7C5F",
          "onTertiaryContainer": "#FFFFFF",
          "error": "#8C0009",
          "onError": "#FFFFFF",
          "errorContainer": "#DA342E",
          "onErrorContainer": "#FFFFFF",
          "background": "#FFF8F1",
          "onBackground": "#1F1B13",
          "surface": "#FFF8F1",
          "onSurface": "#1F1B13",
          "surfaceVariant": "#EBE1CF",
          "onSurfaceVariant": "#484235",
          "outline": "#655E50",
          "outlineVariant": "#817A6B",
          "shadow": "#000000",
          "scrim": "#000000",
          "inverseSurface": "#343027",
          "inverseOnSurface": "#F8F0E2",
          "inversePrimary": "#E2C46D",
          "primaryFixed": "#8B7223",
          "onPrimaryFixed": "#FFFFFF",
          "primaryFixedDim": "#705909",
          "onPrimaryFixedVariant": "#FFFFFF",
          "secondaryFixed": "#807454",
          "onSecondaryFixed": "#FFFFFF",
          "secondaryFixedDim": "#665B3D",
          "onSecondaryFixedVariant": "#FFFFFF",
          "tertiaryFixed": "#5C7C5F",
          "onTertiaryFixed": "#FFFFFF",
          "tertiaryFixedDim": "#446348",
          "onTertiaryFixedVariant": "#FFFFFF",
          "surfaceDim": "#E1D9CC",
          "surfaceBright": "#FFF8F1",
          "surfaceContainerLowest": "#FFFFFF",
          "surfaceContainerLow": "#FBF3E5",
          "surfaceContainer": "#F5EDDF",
          "surfaceContainerHigh": "#F0E7D9",
          "surfaceContainerHighest": "#EAE1D4"
      },
      "light-high-contrast": {
          "primary": "#2C2100",
          "surfaceTint": "#735C0C",
          "onPrimary": "#FFFFFF",
          "primaryContainer": "#534100",
          "onPrimaryContainer": "#FFFFFF",
          "secondary": "#2A2109",
          "onSecondary": "#FFFFFF",
          "secondaryContainer": "#4C4226",
          "onSecondaryContainer": "#FFFFFF",
          "tertiary": "#092812",
          "onTertiary": "#FFFFFF",
          "tertiaryContainer": "#2B4930",
          "onTertiaryContainer": "#FFFFFF",
          "error": "#4E0002",
          "onError": "#FFFFFF",
          "errorContainer": "#8C0009",
          "onErrorContainer": "#FFFFFF",
          "background": "#FFF8F1",
          "onBackground": "#1F1B13",
          "surface": "#FFF8F1",
          "onSurface": "#000000",
          "surfaceVariant": "#EBE1CF",
          "onSurfaceVariant": "#282318",
          "outline": "#484235",
          "outlineVariant": "#484235",
          "shadow": "#000000",
          "scrim": "#000000",
          "inverseSurface": "#343027",
          "inverseOnSurface": "#FFFFFF",
          "inversePrimary": "#FFEAB8",
          "primaryFixed": "#534100",
          "onPrimaryFixed": "#FFFFFF",
          "primaryFixedDim": "#382B00",
          "onPrimaryFixedVariant": "#FFFFFF",
          "secondaryFixed": "#4C4226",
          "onSecondaryFixed": "#FFFFFF",
          "secondaryFixedDim": "#352C12",
          "onSecondaryFixedVariant": "#FFFFFF",
          "tertiaryFixed": "#2B4930",
          "onTertiaryFixed": "#FFFFFF",
          "tertiaryFixedDim": "#15331C",
          "onTertiaryFixedVariant": "#FFFFFF",
          "surfaceDim": "#E1D9CC",
          "surfaceBright": "#FFF8F1",
          "surfaceContainerLowest": "#FFFFFF",
          "surfaceContainerLow": "#FBF3E5",
          "surfaceContainer": "#F5EDDF",
          "surfaceContainerHigh": "#F0E7D9",
          "surfaceContainerHighest": "#EAE1D4"
      },
      "dark": {
          "primary": "#E2C46D",
          "surfaceTint": "#E2C46D",
          "onPrimary": "#3D2F00",
          "primaryContainer": "#574400",
          "onPrimaryContainer": "#FFE08A",
          "secondary": "#D5C5A1",
          "onSecondary": "#393015",
          "secondaryContainer": "#50462A",
          "onSecondaryContainer": "#F2E1BB",
          "tertiary": "#ADCFAE",
          "onTertiary": "#19361F",
          "tertiaryContainer": "#2F4D34",
          "onTertiaryContainer": "#C8ECC9",
          "error": "#FFB4AB",
          "onError": "#690005",
          "errorContainer": "#93000A",
          "onErrorContainer": "#FFDAD6",
          "background": "#16130B",
          "onBackground": "#EAE1D4",
          "surface": "#16130B",
          "onSurface": "#EAE1D4",
          "surfaceVariant": "#4C4639",
          "onSurfaceVariant": "#CFC6B4",
          "outline": "#989080",
          "outlineVariant": "#4C4639",
          "shadow": "#000000",
          "scrim": "#000000",
          "inverseSurface": "#EAE1D4",
          "inverseOnSurface": "#343027",
          "inversePrimary": "#735C0C",
          "primaryFixed": "#FFE08A",
          "onPrimaryFixed": "#241A00",
          "primaryFixedDim": "#E2C46D",
          "onPrimaryFixedVariant": "#574400",
          "secondaryFixed": "#F2E1BB",
          "onSecondaryFixed": "#231B04",
          "secondaryFixedDim": "#D5C5A1",
          "onSecondaryFixedVariant": "#50462A",
          "tertiaryFixed": "#C8ECC9",
          "onTertiaryFixed": "#03210C",
          "tertiaryFixedDim": "#ADCFAE",
          "onTertiaryFixedVariant": "#2F4D34",
          "surfaceDim": "#16130B",
          "surfaceBright": "#3D392F",
          "surfaceContainerLowest": "#110E07",
          "surfaceContainerLow": "#1F1B13",
          "surfaceContainer": "#231F17",
          "surfaceContainerHigh": "#2D2A21",
          "surfaceContainerHighest": "#38342B"
      },
      "dark-medium-contrast": {
          "primary": "#E7C870",
          "surfaceTint": "#E2C46D",
          "onPrimary": "#1D1500",
          "primaryContainer": "#A98E3D",
          "onPrimaryContainer": "#000000",
          "secondary": "#D9C9A5",
          "onSecondary": "#1D1501",
          "secondaryContainer": "#9D906E",
          "onSecondaryContainer": "#000000",
          "tertiary": "#B1D4B2",
          "onTertiary": "#001B07",
          "tertiaryContainer": "#78997A",
          "onTertiaryContainer": "#000000",
          "error": "#FFBAB1",
          "onError": "#370001",
          "errorContainer": "#FF5449",
          "onErrorContainer": "#000000",
          "background": "#16130B",
          "onBackground": "#EAE1D4",
          "surface": "#16130B",
          "onSurface": "#FFFAF6",
          "surfaceVariant": "#4C4639",
          "onSurfaceVariant": "#D3CAB8",
          "outline": "#AAA291",
          "outlineVariant": "#8A8273",
          "shadow": "#000000",
          "scrim": "#000000",
          "inverseSurface": "#EAE1D4",
          "inverseOnSurface": "#2D2A21",
          "inversePrimary": "#594600",
          "primaryFixed": "#FFE08A",
          "onPrimaryFixed": "#171000",
          "primaryFixedDim": "#E2C46D",
          "onPrimaryFixedVariant": "#443400",
          "secondaryFixed": "#F2E1BB",
          "onSecondaryFixed": "#171000",
          "secondaryFixedDim": "#D5C5A1",
          "onSecondaryFixedVariant": "#3F351B",
          "tertiaryFixed": "#C8ECC9",
          "onTertiaryFixed": "#001505",
          "tertiaryFixedDim": "#ADCFAE",
          "onTertiaryFixedVariant": "#1F3C24",
          "surfaceDim": "#16130B",
          "surfaceBright": "#3D392F",
          "surfaceContainerLowest": "#110E07",
          "surfaceContainerLow": "#1F1B13",
          "surfaceContainer": "#231F17",
          "surfaceContainerHigh": "#2D2A21",
          "surfaceContainerHighest": "#38342B"
      },
      "dark-high-contrast": {
          "primary": "#FFFAF6",
          "surfaceTint": "#E2C46D",
          "onPrimary": "#000000",
          "primaryContainer": "#E7C870",
          "onPrimaryContainer": "#000000",
          "secondary": "#FFFAF6",
          "onSecondary": "#000000",
          "secondaryContainer": "#D9C9A5",
          "onSecondaryContainer": "#000000",
          "tertiary": "#F0FFED",
          "onTertiary": "#000000",
          "tertiaryContainer": "#B1D4B2",
          "onTertiaryContainer": "#000000",
          "error": "#FFF9F9",
          "onError": "#000000",
          "errorContainer": "#FFBAB1",
          "onErrorContainer": "#000000",
          "background": "#16130B",
          "onBackground": "#EAE1D4",
          "surface": "#16130B",
          "onSurface": "#FFFFFF",
          "surfaceVariant": "#4C4639",
          "onSurfaceVariant": "#FFFAF6",
          "outline": "#D3CAB8",
          "outlineVariant": "#D3CAB8",
          "shadow": "#000000",
          "scrim": "#000000",
          "inverseSurface": "#EAE1D4",
          "inverseOnSurface": "#000000",
          "inversePrimary": "#352800",
          "primaryFixed": "#FFE5A0",
          "onPrimaryFixed": "#000000",
          "primaryFixedDim": "#E7C870",
          "onPrimaryFixedVariant": "#1D1500",
          "secondaryFixed": "#F6E5BF",
          "onSecondaryFixed": "#000000",
          "secondaryFixedDim": "#D9C9A5",
          "onSecondaryFixedVariant": "#1D1501",
          "tertiaryFixed": "#CCF0CD",
          "onTertiaryFixed": "#000000",
          "tertiaryFixedDim": "#B1D4B2",
          "onTertiaryFixedVariant": "#001B07",
          "surfaceDim": "#16130B",
          "surfaceBright": "#3D392F",
          "surfaceContainerLowest": "#110E07",
          "surfaceContainerLow": "#1F1B13",
          "surfaceContainer": "#231F17",
          "surfaceContainerHigh": "#2D2A21",
          "surfaceContainerHighest": "#38342B"
      }
  },
  "palettes": {
      "primary": {
          "0": "#000000",
          "5": "#171000",
          "10": "#241A00",
          "15": "#302400",
          "20": "#3D2F00",
          "25": "#4A3900",
          "30": "#574400",
          "35": "#655008",
          "40": "#725C16",
          "50": "#8C752D",
          "60": "#A88E44",
          "70": "#C4A95B",
          "80": "#E1C474",
          "90": "#FEE08C",
          "95": "#FFEFCC",
          "98": "#FFF8F1",
          "99": "#FFFBFF",
          "100": "#FFFFFF"
      },
      "secondary": {
          "0": "#000000",
          "5": "#161003",
          "10": "#211B0B",
          "15": "#2B2514",
          "20": "#36301E",
          "25": "#423B28",
          "30": "#4E4633",
          "35": "#5A523E",
          "40": "#665E49",
          "50": "#7F7661",
          "60": "#9A9079",
          "70": "#B5AA92",
          "80": "#D1C5AC",
          "90": "#EEE1C7",
          "95": "#FCF0D5",
          "98": "#FFF8F1",
          "99": "#FFFBFF",
          "100": "#FFFFFF"
      },
      "tertiary": {
          "0": "#000000",
          "5": "#041407",
          "10": "#0E1F11",
          "15": "#182A1B",
          "20": "#223525",
          "25": "#2D402F",
          "30": "#384B3A",
          "35": "#445745",
          "40": "#506351",
          "50": "#687C69",
          "60": "#819681",
          "70": "#9BB19B",
          "80": "#B7CCB6",
          "90": "#D2E8D1",
          "95": "#E0F7DF",
          "98": "#EAFFE8",
          "99": "#F6FFF2",
          "100": "#FFFFFF"
      },
      "neutral": {
          "0": "#000000",
          "5": "#12110D",
          "10": "#1D1B18",
          "15": "#282522",
          "20": "#32302C",
          "25": "#3E3B37",
          "30": "#494642",
          "35": "#55524D",
          "40": "#615E59",
          "50": "#7A7671",
          "60": "#94908B",
          "70": "#AFAAA5",
          "80": "#CBC6C0",
          "90": "#E7E2DB",
          "95": "#F6F0E9",
          "98": "#FFF8F2",
          "99": "#FFFBFF",
          "100": "#FFFFFF"
      },
      "neutral-variant": {
          "0": "#000000",
          "5": "#13110A",
          "10": "#1E1B14",
          "15": "#29251D",
          "20": "#343028",
          "25": "#3F3B32",
          "30": "#4B463D",
          "35": "#575248",
          "40": "#635E54",
          "50": "#7C766C",
          "60": "#969085",
          "70": "#B1AA9F",
          "80": "#CDC6BA",
          "90": "#E9E1D5",
          "95": "#F8F0E3",
          "98": "#FFF8F1",
          "99": "#FFFBFF",
          "100": "#FFFFFF"
      }
  }
}
*/


// Figma generated palette
/*
"schemes": {
  "light": {
      "primary": "#745B0B",
      "surfaceTint": "#745B0B",
      "onPrimary": "#FFFFFF",
      "primaryContainer": "#FFDF92",
      "onPrimaryContainer": "#241A00",
      "secondary": "#6A5D3F",
      "onSecondary": "#FFFFFF",
      "secondaryContainer": "#F3E1BB", 
      "onSecondaryContainer": "#231A04",
      "tertiary": "#486549",
      "onTertiary": "#FFFFFF",
      "tertiaryContainer": "#C9EBC7",
      "onTertiaryContainer": "#04210B",
      "error": "#BA1A1A",
      "onError": "#FFFFFF",
      "errorContainer": "#FFDAD6",
      "onErrorContainer": "#410002",
      "background": "#FFF8F1",
      "onBackground": "#1F1B13",
      "surface": "#FFF8F1",
      "onSurface": "#1F1B13",
      "surfaceVariant": "#ECE1CF",
      "onSurfaceVariant": "#4C4639",
      "outline": "#7E7667",
      "outlineVariant": "#CFC5B4",
      "shadow": "#000000",
      "scrim": "#000000",
      "inverseSurface": "#343027",
      "inverseOnSurface": "#F9F0E2",
      "inversePrimary": "#E5C36C",
      "primaryFixed": "#FFDF92",
      "onPrimaryFixed": "#241A00",
      "primaryFixedDim": "#E5C36C",
      "onPrimaryFixedVariant": "#594400",
      "secondaryFixed": "#F3E1BB",
      "onSecondaryFixed": "#231A04",
      "secondaryFixedDim": "#D6C5A0",
      "onSecondaryFixedVariant": "#51462A",
      "tertiaryFixed": "#C9EBC7",
      "onTertiaryFixed": "#04210B",
      "tertiaryFixedDim": "#AECFAC",
      "onTertiaryFixedVariant": "#314D33",
      "surfaceDim": "#E2D9CC",
      "surfaceBright": "#FFF8F1",
      "surfaceContainerLowest": "#FFFFFF",
      "surfaceContainerLow": "#FCF2E5",
      "surfaceContainer": "#F6EDDF",
      "surfaceContainerHigh": "#F0E7D9",
      "surfaceContainerHighest": "#EAE1D4"
  },
  "light-medium-contrast": {
      "primary": "#544000",
      "surfaceTint": "#745B0B",
      "onPrimary": "#FFFFFF",
      "primaryContainer": "#8D7123",
      "onPrimaryContainer": "#FFFFFF",
      "secondary": "#4D4226",
      "onSecondary": "#FFFFFF",
      "secondaryContainer": "#817354",
      "onSecondaryContainer": "#FFFFFF",
      "tertiary": "#2D492F",
      "onTertiary": "#FFFFFF",
      "tertiaryContainer": "#5E7C5E",
      "onTertiaryContainer": "#FFFFFF",
      "error": "#8C0009",
      "onError": "#FFFFFF",
      "errorContainer": "#DA342E",
      "onErrorContainer": "#FFFFFF",
      "background": "#FFF8F1",
      "onBackground": "#1F1B13",
      "surface": "#FFF8F1",
      "onSurface": "#1F1B13",
      "surfaceVariant": "#ECE1CF",
      "onSurfaceVariant": "#484235",
      "outline": "#655E50",
      "outlineVariant": "#827A6B",
      "shadow": "#000000",
      "scrim": "#000000",
      "inverseSurface": "#343027",
      "inverseOnSurface": "#F9F0E2",
      "inversePrimary": "#E5C36C",
      "primaryFixed": "#8D7123",
      "onPrimaryFixed": "#FFFFFF",
      "primaryFixedDim": "#725908",
      "onPrimaryFixedVariant": "#FFFFFF",
      "secondaryFixed": "#817354",
      "onSecondaryFixed": "#FFFFFF",
      "secondaryFixedDim": "#675B3D",
      "onSecondaryFixedVariant": "#FFFFFF",
      "tertiaryFixed": "#5E7C5E",
      "onTertiaryFixed": "#FFFFFF",
      "tertiaryFixedDim": "#456347",
      "onTertiaryFixedVariant": "#FFFFFF",
      "surfaceDim": "#E2D9CC",
      "surfaceBright": "#FFF8F1",
      "surfaceContainerLowest": "#FFFFFF",
      "surfaceContainerLow": "#FCF2E5",
      "surfaceContainer": "#F6EDDF",
      "surfaceContainerHigh": "#F0E7D9",
      "surfaceContainerHighest": "#EAE1D4"
  },
  "light-high-contrast": {
      "primary": "#2C2100",
      "surfaceTint": "#745B0B",
      "onPrimary": "#FFFFFF",
      "primaryContainer": "#544000",
      "onPrimaryContainer": "#FFFFFF",
      "secondary": "#2A2109",
      "onSecondary": "#FFFFFF",
      "secondaryContainer": "#4D4226",
      "onSecondaryContainer": "#FFFFFF",
      "tertiary": "#0B2811",
      "onTertiary": "#FFFFFF",
      "tertiaryContainer": "#2D492F",
      "onTertiaryContainer": "#FFFFFF",
      "error": "#4E0002",
      "onError": "#FFFFFF",
      "errorContainer": "#8C0009",
      "onErrorContainer": "#FFFFFF",
      "background": "#FFF8F1",
      "onBackground": "#1F1B13",
      "surface": "#FFF8F1",
      "onSurface": "#000000",
      "surfaceVariant": "#ECE1CF",
      "onSurfaceVariant": "#282318",
      "outline": "#484235",
      "outlineVariant": "#484235",
      "shadow": "#000000",
      "scrim": "#000000",
      "inverseSurface": "#343027",
      "inverseOnSurface": "#FFFFFF",
      "inversePrimary": "#FFEABC",
      "primaryFixed": "#544000",
      "onPrimaryFixed": "#FFFFFF",
      "primaryFixedDim": "#392B00",
      "onPrimaryFixedVariant": "#FFFFFF",
      "secondaryFixed": "#4D4226",
      "onSecondaryFixed": "#FFFFFF",
      "secondaryFixedDim": "#352C12",
      "onSecondaryFixedVariant": "#FFFFFF",
      "tertiaryFixed": "#2D492F",
      "onTertiaryFixed": "#FFFFFF",
      "tertiaryFixedDim": "#16331A",
      "onTertiaryFixedVariant": "#FFFFFF",
      "surfaceDim": "#E2D9CC",
      "surfaceBright": "#FFF8F1",
      "surfaceContainerLowest": "#FFFFFF",
      "surfaceContainerLow": "#FCF2E5",
      "surfaceContainer": "#F6EDDF",
      "surfaceContainerHigh": "#F0E7D9",
      "surfaceContainerHighest": "#EAE1D4"
  },
  "dark": {
      "primary": "#E5C36C",
      "surfaceTint": "#E5C36C",
      "onPrimary": "#3E2E00",
      "primaryContainer": "#594400",
      "onPrimaryContainer": "#FFDF92",
      "secondary": "#D6C5A0",
      "onSecondary": "#392F15",
      "secondaryContainer": "#51462A",
      "onSecondaryContainer": "#F3E1BB",
      "tertiary": "#AECFAC",
      "onTertiary": "#1A361E",
      "tertiaryContainer": "#314D33",
      "onTertiaryContainer": "#C9EBC7",
      "error": "#FFB4AB",
      "onError": "#690005",
      "errorContainer": "#93000A",
      "onErrorContainer": "#FFDAD6",
      "background": "#16130B",
      "onBackground": "#EAE1D4",
      "surface": "#16130B",
      "onSurface": "#EAE1D4",
      "surfaceVariant": "#4C4639",
      "onSurfaceVariant": "#CFC5B4",
      "outline": "#989080",
      "outlineVariant": "#4C4639",
      "shadow": "#000000",
      "scrim": "#000000",
      "inverseSurface": "#EAE1D4",
      "inverseOnSurface": "#343027",
      "inversePrimary": "#745B0B",
      "primaryFixed": "#FFDF92",
      "onPrimaryFixed": "#241A00",
      "primaryFixedDim": "#E5C36C",
      "onPrimaryFixedVariant": "#594400",
      "secondaryFixed": "#F3E1BB",
      "onSecondaryFixed": "#231A04",
      "secondaryFixedDim": "#D6C5A0",
      "onSecondaryFixedVariant": "#51462A",
      "tertiaryFixed": "#C9EBC7",
      "onTertiaryFixed": "#04210B",
      "tertiaryFixedDim": "#AECFAC",
      "onTertiaryFixedVariant": "#314D33",
      "surfaceDim": "#16130B",
      "surfaceBright": "#3D392F",
      "surfaceContainerLowest": "#110E07",
      "surfaceContainerLow": "#1F1B13",
      "surfaceContainer": "#231F17",
      "surfaceContainerHigh": "#2E2A21",
      "surfaceContainerHighest": "#39342B"
  },
  "dark-medium-contrast": {
      "primary": "#E9C770",
      "surfaceTint": "#E5C36C",
      "onPrimary": "#1E1500",
      "primaryContainer": "#AB8D3D",
      "onPrimaryContainer": "#000000",
      "secondary": "#DAC9A4",
      "onSecondary": "#1D1501",
      "secondaryContainer": "#9E8F6E",
      "onSecondaryContainer": "#000000",
      "tertiary": "#B2D3B0",
      "onTertiary": "#011B06",
      "tertiaryContainer": "#799979",
      "onTertiaryContainer": "#000000",
      "error": "#FFBAB1",
      "onError": "#370001",
      "errorContainer": "#FF5449",
      "onErrorContainer": "#000000",
      "background": "#16130B",
      "onBackground": "#EAE1D4",
      "surface": "#16130B",
      "onSurface": "#FFFAF6",
      "surfaceVariant": "#4C4639",
      "onSurfaceVariant": "#D4CAB8",
      "outline": "#ABA291",
      "outlineVariant": "#8A8273",
      "shadow": "#000000",
      "scrim": "#000000",
      "inverseSurface": "#EAE1D4",
      "inverseOnSurface": "#2E2A21",
      "inversePrimary": "#5A4500",
      "primaryFixed": "#FFDF92",
      "onPrimaryFixed": "#181000",
      "primaryFixedDim": "#E5C36C",
      "onPrimaryFixedVariant": "#453400",
      "secondaryFixed": "#F3E1BB",
      "onSecondaryFixed": "#181000",
      "secondaryFixedDim": "#D6C5A0",
      "onSecondaryFixedVariant": "#3F351B",
      "tertiaryFixed": "#C9EBC7",
      "onTertiaryFixed": "#001504",
      "tertiaryFixedDim": "#AECFAC",
      "onTertiaryFixedVariant": "#203C23",
      "surfaceDim": "#16130B",
      "surfaceBright": "#3D392F",
      "surfaceContainerLowest": "#110E07",
      "surfaceContainerLow": "#1F1B13",
      "surfaceContainer": "#231F17",
      "surfaceContainerHigh": "#2E2A21",
      "surfaceContainerHighest": "#39342B"
  },
  "dark-high-contrast": {
      "primary": "#FFFAF6",
      "surfaceTint": "#E5C36C",
      "onPrimary": "#000000",
      "primaryContainer": "#E9C770",
      "onPrimaryContainer": "#000000",
      "secondary": "#FFFAF6",
      "onSecondary": "#000000",
      "secondaryContainer": "#DAC9A4",
      "onSecondaryContainer": "#000000",
      "tertiary": "#F0FFEC",
      "onTertiary": "#000000",
      "tertiaryContainer": "#B2D3B0",
      "onTertiaryContainer": "#000000",
      "error": "#FFF9F9",
      "onError": "#000000",
      "errorContainer": "#FFBAB1",
      "onErrorContainer": "#000000",
      "background": "#16130B",
      "onBackground": "#EAE1D4",
      "surface": "#16130B",
      "onSurface": "#FFFFFF",
      "surfaceVariant": "#4C4639",
      "onSurfaceVariant": "#FFFAF6",
      "outline": "#D4CAB8",
      "outlineVariant": "#D4CAB8",
      "shadow": "#000000",
      "scrim": "#000000",
      "inverseSurface": "#EAE1D4",
      "inverseOnSurface": "#000000",
      "inversePrimary": "#362800",
      "primaryFixed": "#FFE4A6",
      "onPrimaryFixed": "#000000",
      "primaryFixedDim": "#E9C770",
      "onPrimaryFixedVariant": "#1E1500",
      "secondaryFixed": "#F7E5BF",
      "onSecondaryFixed": "#000000",
      "secondaryFixedDim": "#DAC9A4",
      "onSecondaryFixedVariant": "#1D1501",
      "tertiaryFixed": "#CEF0CC",
      "onTertiaryFixed": "#000000",
      "tertiaryFixedDim": "#B2D3B0",
      "onTertiaryFixedVariant": "#011B06",
      "surfaceDim": "#16130B",
      "surfaceBright": "#3D392F",
      "surfaceContainerLowest": "#110E07",
      "surfaceContainerLow": "#1F1B13",
      "surfaceContainer": "#231F17",
      "surfaceContainerHigh": "#2E2A21",
      "surfaceContainerHighest": "#39342B"
  }
},
"palettes": {
  "primary": {
      "0": "#000000",
      "5": "#171000",
      "10": "#241A00",
      "15": "#312400",
      "20": "#3E2E00",
      "25": "#4B3900",
      "30": "#594400",
      "35": "#674F00",
      "40": "#745B0D",
      "50": "#8F7426",
      "60": "#AB8D3E",
      "70": "#C7A855",
      "80": "#E5C36D",
      "90": "#FFDF92",
      "95": "#FFEFD0",
      "98": "#FFF8F1",
      "99": "#FFFBFF",
      "100": "#FFFFFF"
  },
  "secondary": {
      "0": "#000000",
      "5": "#161003",
      "10": "#211B0A",
      "15": "#2C2513",
      "20": "#37301D",
      "25": "#433B27",
      "30": "#4F4632",
      "35": "#5B513C",
      "40": "#675D48",
      "50": "#81765F",
      "60": "#9B9077",
      "70": "#B6AA90",
      "80": "#D2C5AB",
      "90": "#EFE1C5",
      "95": "#FEEFD3",
      "98": "#FFF8F1",
      "99": "#FFFBFF",
      "100": "#FFFFFF"
  },
  "tertiary": {
      "0": "#000000",
      "5": "#041506",
      "10": "#0D1F0F",
      "15": "#172A19",
      "20": "#223523",
      "25": "#2D402D",
      "30": "#384C38",
      "35": "#435743",
      "40": "#4F644F",
      "50": "#677D67",
      "60": "#81967F",
      "70": "#9BB199",
      "80": "#B6CDB3",
      "90": "#D2E9CE",
      "95": "#E0F7DC",
      "98": "#EBFFE7",
      "99": "#F6FFF1",
      "100": "#FFFFFF"
  },
  "neutral": {
      "0": "#000000",
      "5": "#13110D",
      "10": "#1D1B17",
      "15": "#282521",
      "20": "#33302C",
      "25": "#3E3B36",
      "30": "#494642",
      "35": "#55524D",
      "40": "#615E59",
      "50": "#7A7671",
      "60": "#95908A",
      "70": "#AFAAA4",
      "80": "#CBC6BF",
      "90": "#E8E1DB",
      "95": "#F6F0E9",
      "98": "#FFF8F1",
      "99": "#FFFBFF",
      "100": "#FFFFFF"
  },
  "neutral-variant": {
      "0": "#000000",
      "5": "#141109",
      "10": "#1F1B13",
      "15": "#29251D",
      "20": "#343027",
      "25": "#403B32",
      "30": "#4B463C",
      "35": "#575248",
      "40": "#635E53",
      "50": "#7D766B",
      "60": "#979084",
      "70": "#B2AA9E",
      "80": "#CEC5B9",
      "90": "#EAE1D4",
      "95": "#F9F0E2",
      "98": "#FFF8F1",
      "99": "#FFFBFF",
      "100": "#FFFFFF"
  }
}
}
*/